import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label="Loïc Guin">
                  <svg className="w-18 h-10 fill-current text-purple-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 110">
                    <path d="M49.168,24.165h0v71.5h37v-14.3h-22.7v-57.2h-14.3Zm100.8,66.4h0a35.068,35.068,0,0,1-17.3,4.4c-16.2,0-27-8.6-32-19.8a38.149,38.149,0,0,1-.5-29.3c4.8-11.5,15.4-20.5,32.6-21a47.41,47.41,0,0,1,16.3,2.4,1.231,1.231,0,0,0,1.5-.7,1.245,1.245,0,0,0-.8-1.5,49.7,49.7,0,0,0-17.1-2.6c-18.2.6-29.6,10.3-34.6,22.5a40.4,40.4,0,0,0,.5,31.1c5.3,12,16.8,21.3,34.1,21.3a39.017,39.017,0,0,0,19.1-5.1,1.274,1.274,0,0,0,.5-1.1v-31.3a1.11,1.11,0,0,0-1.1-1.1,1.2,1.2,0,0,0-1.2,1.1v30.7Zm-1.9-51h0a33.878,33.878,0,0,0-15.4-2.6c-10.8.4-17.7,6.3-20.7,13.8a25.485,25.485,0,0,0,.4,19c3.1,7.4,10,13.1,20.3,13.1a18.4,18.4,0,0,0,4.1-.4,1.028,1.028,0,0,0,1-1.1v-21.5a1.11,1.11,0,0,0-1.1-1.1,1.135,1.135,0,0,0-1.2,1.1v20.4a13.678,13.678,0,0,1-2.8.2c-9.3,0-15.3-5-18.2-11.6a22.3,22.3,0,0,1-.3-17.2c2.7-6.7,8.8-12,18.6-12.3a31.006,31.006,0,0,1,14.4,2.3,1.069,1.069,0,0,0,1.5-.6A1.124,1.124,0,0,0,148.071,39.566Z"/>
                    <path d="M191.551,56.623a7.791,7.791,0,0,1-2.7-5.928V32.6A23.407,23.407,0,0,0,172.31,10.237l-2.5-.728-4.576,14.873,2.5,0.728a7.834,7.834,0,0,1,5.512,7.488v18.1a24.72,24.72,0,0,0,1.352,7.9,24.72,24.72,0,0,0-1.352,7.9V84.6a7.834,7.834,0,0,1-5.512,7.488l-2.5.728,4.576,14.872,2.5-.728A23.406,23.406,0,0,0,188.846,84.6V66.5a7.791,7.791,0,0,1,2.7-5.928l2.392-1.976Z"/>
                    <path d="M8.409,57.6L10.8,59.575a7.793,7.793,0,0,1,2.7,5.928V83.6a23.406,23.406,0,0,0,16.537,22.36l2.5,0.728,4.576-14.872-2.5-.728A7.966,7.966,0,0,1,29.106,83.6V65.5a22.159,22.159,0,0,0-1.352-7.9,22.159,22.159,0,0,0,1.352-7.9V31.6a7.966,7.966,0,0,1,5.512-7.488l2.5-.728L32.538,8.509l-2.5.728A23.407,23.407,0,0,0,13.505,31.6v18.1a7.794,7.794,0,0,1-2.7,5.928Zm7.7-26A20.814,20.814,0,0,1,30.874,11.733l3.016,9.88A10.52,10.52,0,0,0,26.506,31.6v18.1a19.854,19.854,0,0,1-1.56,7.9,20.222,20.222,0,0,1,1.56,7.9V83.6a10.52,10.52,0,0,0,7.384,9.984l-3.016,9.88A20.814,20.814,0,0,1,16.106,83.6V65.5a10.193,10.193,0,0,0-3.64-7.9,10.193,10.193,0,0,0,3.64-7.9V31.6Z"/>
                  </svg>
                </Link>
              </div>
              <div className="text-gray-400">Ce site a été entièrement développé par mes soins.</div>
              <div className="text-gray-400">Veuillez trouver ci-contre les informations importantes.</div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className="md:col-span-12 lg:col-span-7 grid sm:grid-cols-3 gap-8">

              {/* 2nd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Mes coordonnées</h6>
                <ul>
                  <li className="mb-1">
                    <Link to="#" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">contact@loic-guin.fr</Link>
                  </li>
                  <li className="mb-1">
                    <Link to="#" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Lyon, France</Link>
                  </li>
                  <li className="mb-1">
                    <Link to="#" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Disponible en remote</Link>
                  </li>
                </ul>
              </div>

              {/* 3rd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Liens utiles</h6>
                <ul>
                  <li className="mb-1">
                    <Link to="/help" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">CGU</Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/help" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Mentions légales</Link>
                  </li>
                </ul>
              </div>

              {/* 4th block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Me suivre</h6>
                <ul className="flex">
                  <li>
                    <a href="https://www.linkedin.com/in/loicguin" target="_blank" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Linkedin">
                      <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                      </svg>
                    </a>
                  </li>
                  <li className="ml-4">
                    <a href="https://www.malt.fr/profile/loicguin" target="_blank" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Malt">
                      <svg className="w-8 h-8 fill-current" viewBox="0 0 279 276" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(45,235) scale(0.07,-0.07)">
                          <path d="M1347 2750 c-87 -22 -165 -79 -215 -158 -31 -48 -62 -138 -62 -178 0 -15 54 -77 171 -198 l171 -175 169 172 170 172 -4 65 c-8 122 -78 225 -185 273 -64 29 -159 41 -215 27z"/>
                          <path d="M573 2465 c-154 -48 -245 -172 -245 -333 1 -61 6 -84 31 -135 16 -34 48 -81 69 -104 l40 -43 364 0 364 0 53 54 c49 50 53 57 40 73 -44 57 -410 429 -440 448 -79 51 -191 67 -276 40z"/>
                          <path d="M2019 2445 c-25 -8 -64 -27 -86 -42 -22 -16 -387 -372 -811 -793 -744 -739 -771 -767 -792 -825 -32 -88 -26 -209 15 -283 38 -70 112 -136 183 -162 81 -31 195 -25 271 14 42 22 214 187 823 789 876 869 833 818 833 977 0 76 -3 92 -33 152 -37 75 -89 124 -168 159 -62 28 -171 34 -235 14z"/>
                          <path d="M287 1729 c-189 -30 -317 -222 -277 -415 16 -76 39 -120 87 -166 50 -49 115 -76 213 -90 l80 -12 342 339 c189 187 341 341 338 342 -18 6 -744 8 -783 2z"/>
                          <path d="M2037 1393 c-188 -185 -344 -341 -346 -346 -1 -4 181 -7 405 -5 l409 3 59 29 c72 35 126 88 163 158 25 48 28 63 28 153 0 89 -3 106 -27 150 -28 55 -96 123 -148 150 -31 16 -147 44 -183 45 -10 0 -164 -143 -360 -337z"/>
                          <path d="M1510 875 l-53 -54 126 -133 c271 -287 312 -325 367 -354 49 -26 65 -29 150 -29 113 0 167 22 240 95 73 73 95 127 95 240 0 85 -3 101 -28 148 -16 30 -47 74 -69 98 l-41 44 -366 0 -367 0 -54 -55z"/>
                          <path d="M1207 562 c-186 -186 -180 -176 -152 -287 28 -108 106 -195 207 -231 70 -25 177 -21 242 9 112 51 203 174 214 288 l5 51 -169 169 c-93 93 -171 169 -174 169 -3 0 -81 -75 -173 -168z"/>
                        </g>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

            </div>

          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">

            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">&copy; 2022 Loïc GUIN. Tous droits réservés.</div>

          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
