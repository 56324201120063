import React from 'react';

function Profil() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

        {/* Content */}
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4" data-aos="fade-down">Ingénieur développeur C#</h1>
            <h1 className="h1 mb-4" data-aos="fade-up">Freelance</h1>
          </div>

          {/* Profil */}
           <div className="pt-12 pb-12 md:pb-20">
             <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
               <div className="relative block group" data-aos="fade-right" data-aos-delay="200">
                   <img className="w-2/3 sm: mx-auto md:mx-auto lg:mx-auto xl:float-right transform hover:scale-105 transition duration-700 ease-out" src={require('../images/profil.jpg').default} alt="Profil de Loïc" />
               </div>
               <div data-aos="fade-left" data-aos-delay="200">
                 <h3 className="h3 text-2xl lg:text-3xl mb-2">Bienvenue sur mon site !</h3>
                 <p className="text-lg text-gray-400 flex-grow pt-2">Je me présente, Loïc Guin, freelance développeur C#.</p>
                 <p className="text-lg text-gray-400 flex-grow pt-2">Mes expériences professionnelles et ma curiosité m'ont mené à un certain niveau d'expertise en C#.</p>
                 <p className="text-lg text-gray-400 flex-grow pt-2">Je saurai donc répondre à nombre de vos besoins, qu'il s'agise de déloppement web en ASP, de logiciel en WPF ou de jeux vidéo sous Unity !</p>
                 <p className="text-lg text-gray-400 flex-grow pt-2">Au plaisir de discuter avec vous de vos prochains défis !</p>
               </div>
             </article>
           </div>

        </div>

      </div>
    </section>
  );
}

export default Profil;