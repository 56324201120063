import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Profil from '../partials/Profil';
import Process from '../partials/Process';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import FeaturesZigzag from '../partials/FeaturesZigzag';
import Clients from '../partials/Clients';
import Contact from '../partials/Contact';
import Footer from '../partials/Footer';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <Profil />
        <Process />
        <FeaturesBlocks />
        <FeaturesZigzag />
        <Clients />
        <Contact />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;