import React from 'react';

function Clients() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">Ils m'ont fait confiance !</h1>
          </div>

          {/* Items */}
          <div className="grid gap-2 grid-cols-2 md:grid-cols-2" data-aos-id-clients>

            {/* Fresenius Kabi */}
            <div className="flex items-center justify-center h-24 border border-gray-700 p-2" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-clients]">
            <svg className="max-w-full fill-current text-gray-500" width="150" height="48" viewBox="0 0 1280.000000 384.000000" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(0.000000,384.000000) scale(0.100000,-0.100000)">
                    <path d="M320 3536 c0 -7 7 -19 16 -27 49 -40 94 -204 94 -338 0 -42 -11 -148 -25 -236 -48 -310 -30 -445 72 -556 62 -66 127 -109 303 -200 108 -55 136 -75 211 -149 101 -100 140 -174 171 -320 19 -89 21 -139 25 -757 l5 -663 239 0 239 0 0 653 c0 615 -1 660 -20 767 -28 153 -76 248 -174 342 -58 56 -99 82 -229 149 -252 130 -313 184 -359 317 -29 87 -29 219 -1 397 28 174 36 306 22 380 -13 75 -54 180 -86 223 l-24 32 -240 0 c-196 0 -239 -2 -239 -14z" />
                    <path d="M1210 3546 c0 -2 18 -30 39 -62 57 -86 74 -143 79 -264 3 -81 -2 -141 -22 -260 -46 -286 -44 -374 12 -494 48 -101 133 -172 322 -266 251 -126 361 -247 423 -469 21 -74 22 -96 25 -759 l3 -682 245 0 245 0 -4 667 c-3 593 -5 679 -21 763 -26 134 -77 231 -169 324 -63 63 -92 82 -233 155 -256 132 -332 200 -371 331 -23 76 -21 217 6 365 53 295 32 495 -65 622 l-25 33 -245 0 c-134 0 -244 -2 -244 -4z" />
                    <path d="M2100 3541 c0 -5 7 -14 15 -21 22 -18 65 -96 81 -148 27 -86 28 -232 4 -392 -47 -317 -46 -402 10 -517 48 -97 127 -162 321 -263 124 -65 179 -100 233 -149 89 -82 108 -107 145 -195 60 -145 61 -159 61 -896 l0 -670 250 0 250 0 0 653 c0 652 0 652 -24 766 -34 158 -76 240 -173 335 -64 62 -98 86 -216 147 -298 155 -369 227 -397 404 -13 81 -8 146 27 345 21 120 24 167 21 270 -5 138 -21 195 -80 285 l-33 50 -248 3 c-141 1 -247 -2 -247 -7z" />
                    <path d="M7708 2795 c-67 -18 -130 -57 -176 -110 -124 -141 -83 -356 85 -448 28 -14 107 -40 178 -57 70 -17 141 -38 157 -48 38 -22 46 -56 21 -90 -18 -25 -27 -27 -99 -30 -102 -4 -191 21 -281 78 -12 8 -24 12 -26 10 -80 -99 -137 -174 -137 -180 0 -5 15 -20 33 -33 110 -83 312 -128 478 -108 201 25 309 136 309 315 0 79 -22 143 -67 195 -47 55 -79 70 -273 130 -195 59 -216 76 -164 128 25 25 29 25 117 20 96 -5 148 -21 203 -64 l32 -24 39 53 c111 152 109 147 87 163 -47 37 -183 95 -244 105 -87 13 -214 11 -272 -5z" />
                    <path d="M11947 2800 c-141 -36 -244 -151 -255 -286 -7 -91 18 -156 84 -221 60 -58 114 -82 255 -113 98 -21 163 -52 187 -87 13 -21 12 -25 -15 -52 -58 -58 -176 -51 -323 20 -68 32 -86 38 -95 27 -129 -167 -129 -167 -109 -189 26 -29 146 -84 229 -104 105 -27 270 -25 361 3 143 44 214 143 214 300 0 94 -27 159 -87 213 -45 41 -92 60 -276 115 -71 20 -135 44 -143 51 -20 21 -17 50 7 74 20 20 30 21 113 17 92 -5 138 -20 209 -70 16 -11 29 -19 31 -17 1 2 33 48 70 103 53 76 65 101 54 108 -72 47 -132 79 -178 93 -65 19 -277 29 -333 15z" />
                    <path d="M4810 2290 l0 -500 130 0 130 0 0 200 0 200 175 0 175 0 0 115 0 115 -175 0 -175 0 0 70 0 70 245 0 245 0 0 115 0 115 -375 0 -375 0 0 -500z" />
                    <path d="M5650 2290 l0 -500 130 0 130 0 0 170 0 170 68 0 67 0 82 -170 81 -170 146 0 c80 0 146 1 146 3 0 1 -43 85 -95 187 l-95 185 22 15 c55 36 107 94 127 140 47 110 36 258 -25 343 -41 57 -77 81 -163 107 -57 18 -92 20 -342 20 l-279 0 0 -500z m528 261 c62 -33 61 -144 -1 -181 -27 -17 -51 -20 -149 -20 l-118 0 0 111 0 111 123 -4 c72 -2 132 -9 145 -17z" />
                    <path d="M6620 2290 l0 -500 385 0 385 0 0 110 0 110 -255 0 -255 0 0 95 0 95 160 0 160 0 0 115 0 115 -160 0 -160 0 0 65 0 65 235 0 235 0 0 115 0 115 -365 0 -365 0 0 -500z" />
                    <path d="M8380 2290 l0 -500 385 0 385 0 0 110 0 110 -255 0 -255 0 0 95 0 95 160 0 160 0 0 115 0 115 -160 0 -160 0 0 65 0 65 235 0 235 0 0 115 0 115 -365 0 -365 0 0 -500z" />
                    <path d="M9260 2290 l0 -500 125 0 125 0 2 275 3 274 194 -274 194 -275 109 0 108 0 0 500 0 500 -125 0 -125 0 -2 -260 -3 -261 -185 261 -185 260 -117 0 -118 0 0 -500z" />
                    <path d="M10290 2290 l0 -500 135 0 135 0 0 500 0 500 -135 0 -135 0 0 -500z" />
                    <path d="M10715 2543 c-8 -266 -3 -388 20 -477 40 -152 132 -245 276 -276 79 -17 270 -9 329 15 102 41 174 120 213 236 20 59 21 88 25 407 l3 342 -135 0 -136 0 0 -308 c0 -363 -5 -394 -72 -442 -33 -24 -51 -30 -92 -30 -72 0 -111 27 -142 96 -24 54 -24 58 -24 369 l0 315 -129 0 -129 0 -7 -247z" />
                    <path d="M4810 795 l0 -505 135 0 135 0 0 153 0 152 55 68 54 69 111 -221 110 -221 155 0 c98 0 155 4 155 10 0 6 -74 149 -165 319 -90 170 -166 313 -167 319 -2 5 63 86 145 180 81 95 147 174 147 177 0 3 -71 5 -157 5 l-158 -1 -142 -180 -143 -180 0 181 0 180 -135 0 -135 0 0 -505z" />
                    <path d="M6107 1258 c-70 -185 -347 -952 -347 -959 0 -5 61 -9 138 -9 l137 0 26 97 25 98 164 0 163 0 30 -98 30 -97 140 0 c107 0 138 3 135 13 -2 6 -85 234 -184 504 l-179 493 -131 0 -131 0 -16 -42z m193 -408 c22 -73 40 -137 40 -142 0 -4 -40 -8 -89 -8 l-88 0 45 145 c25 79 47 143 49 141 2 -1 21 -63 43 -136z" />
                    <path d="M6840 794 l0 -506 288 5 c308 5 351 11 428 60 87 55 134 144 134 256 0 110 -26 163 -104 211 l-28 18 26 17 c35 23 72 89 81 142 9 59 -11 144 -46 193 -64 88 -171 110 -546 110 l-233 0 0 -506z m528 270 c31 -22 44 -58 32 -89 -16 -41 -65 -55 -190 -55 l-110 0 0 80 0 80 123 0 c96 0 127 -3 145 -16z m39 -380 c22 -22 25 -32 21 -76 -4 -40 -11 -55 -34 -74 -27 -22 -37 -23 -161 -24 l-133 0 0 100 0 100 140 0 c138 0 141 -1 167 -26z" />
                    <path d="M7840 795 l0 -505 135 0 135 0 0 505 0 505 -135 0 -135 0 0 -505z" />
                </g>
            </svg>
            </div>

            {/* ATEO */}
            <div className="flex items-center justify-center h-24 border border-gray-700 p-2" data-aos="fade-up" data-aos-anchor="[data-aos-id-clients]">
              <svg className="max-w-full fill-current text-gray-500" width="150" height="48" viewBox="0 0 592.000000 215.000000" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(0.000000,215.000000) scale(0.100000,-0.100000)" >
                  <path d="M1890 1805 l0 -225 -85 0 -85 0 0 -150 0 -150 85 0 85 0 0 -372 c0 -205 4 -400 10 -433 13 -84 65 -182 117 -221 79 -60 142 -77 283 -78 104 0 136 4 190 23 116 40 110 29 110 197 0 135 -1 146 -17 139 -116 -48 -226 -60 -282 -30 -58 30 -61 48 -61 429 l0 346 180 0 180 0 0 150 0 150 -180 0 -180 0 0 225 0 225 -175 0 -175 0 0 -225z"/>
                  <path d="M770 1610 c-162 -21 -301 -90 -420 -210 -94 -94 -139 -163 -176 -268 -67 -194 -54 -385 40 -577 93 -190 270 -329 483 -380 99 -24 289 -17 386 14 l67 22 0 210 0 211 -47 -35 c-68 -49 -100 -63 -171 -77 -196 -39 -393 102 -432 309 -29 154 66 328 218 399 49 23 70 27 152 27 82 0 103 -4 152 -27 109 -51 192 -156 217 -276 7 -33 11 -191 11 -422 l0 -370 175 0 175 0 0 388 c0 222 -5 415 -11 452 -24 152 -84 271 -193 387 -117 124 -268 201 -438 223 -90 11 -106 11 -188 0z"/>
                  <path d="M3252 1600 c-349 -94 -579 -445 -533 -815 38 -313 242 -550 541 -631 102 -28 293 -25 390 5 148 46 268 125 364 242 47 57 116 179 116 205 0 12 -30 14 -177 14 l-178 0 -47 -54 c-30 -34 -69 -65 -105 -83 -53 -25 -68 -28 -163 -28 -97 0 -110 2 -170 31 -91 43 -183 139 -205 212 l-6 22 539 0 540 0 6 23 c3 12 9 63 12 112 22 321 -175 623 -475 729 -78 27 -100 30 -231 33 -119 2 -158 -1 -218 -17z m290 -301 c118 -25 218 -108 257 -215 35 -92 68 -84 -354 -84 -293 0 -375 3 -375 13 0 24 58 133 93 172 83 96 240 143 379 114z"/>
                  <path d="M4934 1599 c-140 -23 -266 -91 -379 -204 -133 -133 -200 -278 -212 -459 -23 -357 206 -670 557 -757 78 -20 269 -17 350 4 235 62 425 238 506 469 36 100 45 294 20 401 -37 154 -137 311 -258 402 -166 126 -379 178 -584 144z m286 -371 c115 -53 197 -159 219 -284 25 -138 -44 -294 -166 -372 -194 -123 -443 -52 -546 157 -29 59 -32 74 -32 156 0 80 3 97 31 155 47 99 118 161 233 206 59 23 194 13 261 -18z"/>
                </g>
              </svg>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;