import React from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import EmailConfig from '../utils/Email';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
            accepted: false,
            errors: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        // First Name
        if (typeof this.state.firstName !== "undefined") {
            if (!this.state.firstName.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/)) {
                formIsValid = false;
                errors["firstName"] = "Certains charactères ne sont pas autorisés";
            }
        }
        if (this.state.firstName === "") {
            formIsValid = false;
            errors["firstName"] = "Prénom requis";
        }

        // Last Name
        if (typeof this.state.lastName !== "undefined") {
            if (!this.state.lastName.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/)) {
                formIsValid = false;
                errors["lastName"] = "Certains charactères ne sont pas autorisés";
            }
        }
        if (this.state.lastName === "") {
            formIsValid = false;
            errors["lastName"] = "Nom requis";
        }

        // Email
        if (typeof this.state.email !== "undefined") {
            let lastAtPos = this.state.email.lastIndexOf("@");
            let lastDotPos = this.state.email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    this.state.email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    this.state.email.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "L'email n'est pas valide";
            }
        }
        if (this.state.email === "") {
            formIsValid = false;
            errors["email"] = "Email requis";
        }

        // Subject
        if (this.state.subject === "") {
            formIsValid = false;
            errors["subject"] = "Sujet requis";
        }

        // Message
        if (typeof this.state.message !== "undefined") {
            if (this.state.message.length < 30) {
                formIsValid = false;
                errors["message"] = "Veuillez écrire un message d'au moins 30 charactères";
            }
        }
        if (this.state.message === "") {
            formIsValid = false;
            errors["message"] = "Message requis";
        }

        // CGU
        if (!this.state.accepted) {
            formIsValid = false;
            errors["accepted"] = "Veuillez accepter les Conditions Générales d'Utilisation";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.handleValidation()) {
            var templateParams = {
                firstname: this.state.firstName,
                lastname: this.state.lastName,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message,
            };

            // Send email
            toast.promise(
                emailjs.send(EmailConfig.SERVICE_ID, EmailConfig.TEMPLATE_ID, templateParams, EmailConfig.USER_ID),
                {
                    pending: "Envoie du mail en cours...",
                    success: "Email envoyé !",
                    error: "Une erreur est survenue..."
                },
                {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true
                }
            ).then(() => {
                // Cleanup form values
                this.setState({
                    firstName: "",
                    lastName: "",
                    email: "",
                    subject: "",
                    message: "",
                    accepted: "",
                    errors: {}
                });
            });
        }
    }

    render() {
        return (
            <section className="relative">
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />
                <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                    <div className="pt-16 pb-12 md:pt-20 md:pb-20 border-t border-gray-800">

                        {/* Page header */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16" id="Contact">
                            <h1 className="h1 mb-4" data-aos="fade-up">Me contacter</h1>
                            <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">Prenons rendez-vous pour décider de la solution dont <strong>vous</strong> avez besoin.</p>
                        </div>

                        {/* Contact form */}
                        <form className="max-w-xl mx-auto" onSubmit={this.handleSubmit}>
                            <div className="flex flex-wrap -mx-3 mb-4" data-aos="fade-up">
                                <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">Prénom <span className="text-red-600">*</span></label>
                                    <input id="first-name" name="firstName" type="text" className={"form-input w-full text-gray-300 " + (typeof this.state.errors["firstName"] !== "undefined" ? 'border-red-500 focus:border-red-500' : '')} placeholder="Votre prénom" value={this.state.firstName} onChange={this.handleInputChange} />
                                    {this.state.errors["firstName"] !== "" &&
                                        <p className="text-red-500 text-sm mt-2">{this.state.errors["firstName"]}</p>
                                    }
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">Nom <span className="text-red-600">*</span></label>
                                    <input id="last-name" name="lastName" type="text" className={"form-input w-full text-gray-300 " + (typeof this.state.errors["lastName"] !== "undefined" ? 'border-red-500 focus:border-red-500' : '')} placeholder="Votre nom" value={this.state.lastName} onChange={this.handleInputChange} />
                                    {this.state.errors["lastName"] !== "" &&
                                        <p className="text-red-500 text-sm mt-2">{this.state.errors["lastName"]}</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4" data-aos="fade-up">
                                <div className="w-full px-3">
                                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                                    <input id="email" name="email" type="email" className={"form-input w-full text-gray-300 " + (typeof this.state.errors["email"] !== "undefined" ? 'border-red-500 focus:border-red-500' : '')} placeholder="Votre adresse email" value={this.state.email} onChange={this.handleInputChange} />
                                    {this.state.errors["email"] !== "" &&
                                        <p className="text-red-500 text-sm mt-2">{this.state.errors["email"]}</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4" data-aos="fade-up">
                                <div className="w-full px-3">
                                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">Sujet <span className="text-red-600">*</span></label>
                                    <input id="subject" name="subject" type="text" className={"form-input w-full text-gray-300 " + (typeof this.state.errors["subject"] !== "undefined" ? 'border-red-500 focus:border-red-500' : '')} placeholder="Comment puis-je vous aidez ?" value={this.state.subject} onChange={this.handleInputChange} />
                                    {this.state.errors["subject"] !== "" &&
                                        <p className="text-red-500 text-sm mt-2">{this.state.errors["subject"]}</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4" data-aos="fade-up">
                                <div className="w-full px-3">
                                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">Message <span className="text-red-600">*</span></label>
                                    <textarea id="message" name="message" rows="4" className={"form-input w-full text-gray-300 " + (typeof this.state.errors["message"] !== "undefined" ? 'border-red-500 focus:border-red-500' : '')} placeholder="Ecrivez votre message" value={this.state.message} onChange={this.handleInputChange} ></textarea>
                                    {this.state.errors["message"] !== "" &&
                                        <p className="text-red-500 text-sm mt-2">{this.state.errors["message"]}</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4" data-aos="fade-up">
                                <div className="w-full px-3">
                                    <label className="flex items-center">
                                        <input name="accepted" type="checkbox" className="form-checkbox" checked={this.state.accepted} onChange={this.handleInputChange} />
                                        <span className="text-gray-400 ml-2">Je reconnais avoir lu et accepté les <Link to="/help" className="text-purple-600 hover:text-purple-400">Conditions Générales d'Utilisation </Link><span className="text-red-600">*</span></span>
                                    </label>
                                    {this.state.errors["accepted"] !== "" &&
                                        <p className="text-red-500 text-sm mt-2">{this.state.errors["accepted"]}</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mt-6" data-aos="fade-up">
                                <div className="w-full px-3">
                                    <button type="submit" className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">Envoyer</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;