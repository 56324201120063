import React, { useState } from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';

function Help() {

  const [page, setPage] = useState(1);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="flex flex-col md:flex-row">

              {/* Main content */}
              <main className="md:flex-auto md:pl-10 order-1" data-aos="fade-up">

                {/* CGU */}
                <div className={page !== 1 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Condition générales d'utilisation (CGU)</h2>
                    <p className="text-gray-400">Dernière mise à jour - <span className="text-purple-600">11 Janvier 2022</span></p>
                  </div>
                  <ul className="-my-4">
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">Object et champ d'application des CGU</h4>
                      <p className="text-lg text-gray-400">Les présentes Conditions Générales d’Utilisation dénommées « CGU », régissent les rapports entre Loïc GUIN et les utilisateurs du site loic-guin.fr.</p>
                      <p className="text-lg text-gray-400">Les « utilisateurs » désignant toute personne consultant le site web loic-guin.fr.</p>
                      <p className="text-lg text-gray-400">L’utilisateur est tenu d’accepter les présentes CGU au moment de soumettre son formulaire de contact en cochant la case « Je reconnais avoir lu et accepté les Conditions Générales d’Utilisation ».</p>
                      <p className="text-lg text-gray-400">Loïc GUIN pourra modifier les présentes CGU à tout moment, sans préavis. Il est ainsi recommandé de les consulter régulièrement.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">Mentions légales</h4>
                      <p className="text-lg text-gray-400">Afin de prendre connaissance des mentions légales du site loic-guin.fr, veuillez vous référer à la 
                        <a className={`text-purple-600 hover:text-purple-400 ${page === 1 && 'text-purple-600'}`} href="#0" onClick={(e) => { e.preventDefault(); setPage(2); }}> section dédiée</a>.
                      </p>
                      <p className="text-lg text-gray-400">En acceptant les CGU du site loic-guin.fr, vous acceptez par la même occasion l'entièreté du contenu des mentions légales du site.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">Services proposés sur le site loic-guin.fr</h4>
                      <p className="text-lg text-gray-400">Le site loic-guin.fr a pour vocation de présenter son détenteur et créateur, Loïc GUIN, ainsi que les services proposés par ce dernier.</p>
                      <p className="text-lg text-gray-400">Aucun service d'achat ou de vente ne vous sera proposé sur ce site.</p>
                      <p className="text-lg text-gray-400">Si vous constatez une erreur concernant des informations que nous aurions pu omettre, n’hésitez pas à nous le signaler.</p>
                    </li>
                  </ul>
                </div>

                {/* Legal mentions */}
                <div className={page !== 2 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Mentions légales</h2>
                    <p className="text-gray-400">Dernière mise à jour - <span className="text-purple-600">11 Janvier 2022</span></p>
                  </div>
                  <div className="py-4">
                    <p className="text-lg text-gray-400">Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir ses pages. En vous connectant sur ce site, vous acceptez, sans réserves, les présentes modalités.</p>
                    <p className="text-lg text-gray-400">Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, le responsable du présent site internet loic-guin.fr est :</p>
                  </div>
                  <ul className="-my-4">
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">1. Editeur du site </h4>
                      <p className="text-lg text-gray-400">Auto entrepreneur Loïc GUIN</p>
                      <p className="text-lg text-gray-400">Numéro de SIRET : 908 745 029 00011</p>
                      <p className="text-lg text-gray-400">Adresse : 62 Avenue Jean-François Raclet, 69007 Lyon</p>
                      <p className="text-lg text-gray-400">Téléphone : +33673795509</p>
                      <p className="text-lg text-gray-400">Email : contact@loic-guin.fr</p>
                      <p className="text-lg text-gray-400">Site web : <a className="text-purple-600 hover:text-purple-400" href="https://loic-guin.fr">loic-guin.fr</a></p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">2. Hébergement</h4>
                      <p className="text-lg text-gray-400">Hébergeur : Gandi SAS</p>
                      <p className="text-lg text-gray-400">63, 65 Boulevard Massena, 75013 Paris, France</p>
                      <p className="text-lg text-gray-400">Téléphone : +33170377661</p>
                      <p className="text-lg text-gray-400">Site web : <a className="text-purple-600 hover:text-purple-400" href="https://gandi.net">gandi.net</a></p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">3. Développement </h4>
                      <p className="text-lg text-gray-400">Ce site web a été développé par Loïc GUIN.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">4. Conditions d’utilisation</h4>
                      <p className="text-lg text-gray-400">Ce site (loic-guin.fr) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort d’utilisation et un graphisme plus agréable.</p>
                      <p className="text-lg text-gray-400">Nous vous recommandons de recourir à des navigateurs modernes comme  Google Chrome, Firefox, Safari, Edge, etc…</p>
                      <p className="text-lg text-gray-400">Loïc GUIN met en œuvre tous les moyens dont il dispose, pour assurer une information fiable et une mise à jour fiable de son site internet.</p>
                      <p className="text-lg text-gray-400">Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc s’assurer de l’exactitude des informations auprès de Loïc GUIN, et signaler toutes modifications du site qu’il jugerait utile. 
                                              Loïc GUIN n’est en aucun cas responsable de l’utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">5. Cookies</h4>
                      <p className="text-lg text-gray-400">Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez.</p>
                      <p className="text-lg text-gray-400">Ce site (loic-guin.fr) ne fait pas l'usage de cookies et donc aucune demande d'acceptation de cookies ne devrait vous être demander lors de la navigation sur ce site.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">6. Liens hypertextes</h4>
                      <p className="text-lg text-gray-400">Ce site internet (loic-guin.fr) peut offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet.</p>
                      <p className="text-lg text-gray-400">Loïc GUIN ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. 
                                              Il ne peut être tenu pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. 
                                              Les risques liés à cette utilisation incombent pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">7. Services fournis</h4>
                      <p className="text-lg text-gray-400">L’ensemble des activités de Loïc GUIN ainsi que ses informations sont présentés sur ce site loic-guin.fr.</p>
                      <p className="text-lg text-gray-400">Loïc GUIN s’efforce de fournir sur le site loic-guin.fr des informations aussi précises que possible. 
                                              Les renseignements figurant sur le site loic-guin.fr ne sont pas exhaustifs et les photos non contractuelles.</p>
                      <p className="text-lg text-gray-400">Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                                              Par ailleurs, toutes les informations indiquées sur le site loic-guin.fr sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">8. Limitation contractuelles sur les données</h4>
                      <p className="text-lg text-gray-400">Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions.</p>
                      <p className="text-lg text-gray-400">Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par courriel, à l’adresse contact@loic-guin.fr, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).</p>
                      <p className="text-lg text-gray-400">De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.</p>
                      <p className="text-lg text-gray-400">Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de Loïc GUIN.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">9. Propriété intellectuelle</h4>
                      <p className="text-lg text-gray-400">Tout le contenu du présent site loic-guin.fr, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de Loïc GUIN à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.</p>
                      <p className="text-lg text-gray-400">Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de Loïc GUIN.</p>
                      <p className="text-lg text-gray-400">Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.</p>
                      <p className="text-lg text-gray-400">Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des contenus copiés pourraient intenter une action en justice à votre encontre.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">10. Litiges </h4>
                      <p className="text-lg text-gray-400">Les présentes conditions du site loic-guin.fr sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend Loïc GUIN.</p>
                      <p className="text-lg text-gray-400">La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">11. Données personnelles</h4>
                      <p className="text-lg text-gray-400">De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet loic-guin.fr.</p>
                      <p className="text-lg text-gray-400">Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par le site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom et votre adresse électronique. Tel est le cas lorsque vous remplissez le formulaire de prise de contact qui vous est proposé en ligne.</p>
                      <p className="text-lg text-gray-400">Dans ce cas précis, les données renseignées sont utilisées dans le seul but de générer un email de contact automatique et ne sont en aucun cas conservées après envoie dudit email.</p>
                    </li>
                  </ul>
                </div>

              </main>

              {/* Nav sidebar */}
              <aside className="md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0" data-aos="fade-up" data-aos-delay="200">
                <h4 className="text-lg font-medium px-3 pb-3 border-b border-gray-800">Sélectionnez une section</h4>
                <nav>
                  <ul>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 1 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(1); }}
                      >
                        <span>Conditions générales d'utilisation</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 2 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(2); }}
                      >
                        <span>Mentions légales</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </aside>

            </div>
          </div>
        </div>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Help;